@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

body {
  background-color: #140f34;
  color: #fff;
  min-height: 100vh;
  min-width: 100%;
}

.wrapper {
  width: 85%;
  margin: auto;
  padding: 30px;
  background-color: transparent;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  font-size: 60px;
}

.logomain {
  height: 80px;
}

.main {
  height: fit-content;
}

.list-house {
  height: 320px;
  margin-top: 50px;
  padding: 20px;
  background-color: #28274f;
  border-radius: 15px;
}

.list-house h2 {
  margin-bottom: 10px;
}

.bottom {
  height: 400px;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

.bucket {
  width: 23%;
}

.yes {
  height: 300px;
  padding: 2px;
  position: relative;
  background-color: #28274f;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 15px;
}

.yes h2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.yes::before {
  background-image: linear-gradient(90deg, #8a86ff 0.04%, #e13cbd 99.5%);
  content: "";
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  z-index: -1;
  border-radius: 15px;
}

.abstain {
  height: 300px;
  padding: 2px;
  position: relative;
  background-color: #28274f;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 15px;
}

.abstain h2 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.abstain::before {
  background-image: linear-gradient(90deg, #8a86ff 0.04%, #e13cbd 99.5%);
  content: "";
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  z-index: -1;
  border-radius: 15px;
}

.no {
  height: 300px;
  padding: 2px;
  position: relative;
  background-color: #28274f;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 15px;
}

.no h2 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.no::before {
  background-image: linear-gradient(90deg, #8a86ff 0.04%, #e13cbd 99.5%);
  content: "";
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  z-index: -1;
  border-radius: 15px;
}

.noveto {
  height: 300px;
  padding: 2px;
  position: relative;
  background-color: #28274f;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 15px;
}

.noveto h2 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.noveto::before {
  background-image: linear-gradient(90deg, #8a86ff 0.04%, #e13cbd 99.5%);
  content: "";
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  z-index: -1;
  border-radius: 15px;
}

.logo-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flipside-logo {
  width: 250px;
  height: 60px;
}

.imp-logo {
  width: 150px;
  height: 40px;
}

.footer-links {
  text-decoration: none;
  color: #fff;
}

.footer-links:visited {
  text-decoration: none;
  color: #fff;
}

.valhouse {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 10px;
  cursor: pointer;
  scrollbar-width: thin;
  scrollbar-color: #025dff transparent;
}

.valhouse::-webkit-scrollbar {
  background-color: transparent;
  height: 10px;
  cursor: pointer;
}

.valhouse::-webkit-scrollbar-thumb {
  background-color: #025dff;
  border-radius: 5px;
  cursor: pointer;
}

.val-container {
  display: flex;
  justify-content: space-between;
  width: 300px;
  max-width: 300px;
  height: 70px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  background-color: #140f34;
  border-radius: 10px;
}

.logo-box {
  display: flex;
  align-items: center;
}

.vlogo {
  height: 50px;
  width: 50px;
  border-radius: 10px;
  margin-right: 10px;
}

.vinfo {
  display: flex;
  align-items: center;
}

.vinfo h2 {
  display: flex;
}

.osmolabel {
  text-align: right;
}

.results {
  height: 320px;
  margin-top: 15px;
  padding: 20px;
  background-color: #28274f;
  border-radius: 15px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.results::-webkit-scrollbar {
  display: none;
}

.results h2 {
  margin-bottom: 10px;
}

.res-header {
  display: flex;
  justify-content: space-between;
}

.results-main {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 15px;
}

.results-left {
  width: 20%;
}

.dough {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}

.results-right {
  width: 80%;
  margin-left: 40px;
}

.draggable {
  cursor: grab;
  z-index: 10;
  display: flex;
  align-items: center;
}

.draggable-overlay {
  cursor: grabbing;
}

.droppable {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.droppable::-webkit-scrollbar {
  display: none;
}

.category {
  text-align: center;
}

.rooty {
  height: 260px;
  width: 100%;
  z-index: 10;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  flex-direction: column;
}

.tallies {
  display: flex;
  gap: 2%;
  justify-content: space-between;
}

.tallyjawn {
  width: 22%;
  background-color: #140f34;
  border: 2px solid transparent;
  border-radius: 15px;
  padding: 10px;
}

.turnout {
  margin-bottom: 20px;
}

.tttt {
  display: flex;
  justify-content: space-between;
}

.yescolor {
  color: #055dff;
}

.nocolor {
  color: #d630f7;
}

.nwvcolor {
  color: #ff0077;
}

.abscolor {
  color: #6161ab;
}

.tallybutton {
  height: 40px;
  width: 150px;
  margin-bottom: 20px;
  background-color: #140f34;
  color: #ffffff;
  font-size: large;
  font-weight: bold;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  border: 2px solid #8f82fb;
}

.tallybutton:hover {
  color: #025dff;
}

.resetbutton {
  height: 40px;
  width: 150px;
  margin-bottom: 20px;
  background-color: #140f34;
  color: #ffffff;
  font-size: large;
  font-weight: bold;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  border: 2px solid #8f82fb;
  /* margin-left: 20px; */
}

.resetbutton:hover {
  color: #025dff;
}

.footer {
  position: relative;
  margin-top: 80px;
  text-align: center;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-mode {
  margin-left: 20px;
  cursor: pointer;
}

.party-desc {
  display: flex;
  margin-top: 50px;
  padding: 20px;
  background-color: #28274f;
  border-radius: 15px;
}

.desc-content {
  display: flex;
  width: 100%;
}

.desc-desc {
  width: 80%;
}

.desc-desc h1 {
  margin-bottom: 10px;
}

.desc-desc h3 {
  font-weight: normal;
  text-align: justify;
}

.desc-box {
  width: 15%;
  margin-left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.party-logo {
  color: #025dff;
}

.bottom2 {
  margin-top: 50px;
}

.list-house2 {
  height: fit-content;
  padding: 20px;
  background-color: #28274f;
  border-radius: 15px;
}

.valhouse2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.val-container2 {
  display: flex;
  justify-content: space-between;
  width: 300px;
  max-width: 300px;
  height: 70px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  margin-right: 20px;
  background-color: #140f34;
  border-radius: 10px;
}

.logo-box2 {
  display: flex;
  align-items: center;
}

.vlogo2 {
  height: 50px;
  width: 50px;
  border-radius: 10px;
}

.name-box2 {
  display: flex;
  align-items: center;
}

.results2 {
  margin-top: 50px;
  height: 450px;
  padding: 20px;
  background-color: #28274f;
  border-radius: 15px;
}

.chart-area {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
}

.party-text {
  margin-bottom: 10px;
}

.threshold {
  color: #8f82fb;
  font-weight: bold;
}

.tab {
  position: relative;
  cursor: pointer;
}

.tab.active::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #025dff;
  border-radius: 15px;
}

.tab-divider {
  margin: 0px 20px;
  color: #ffffff;
  font-size: 1.2rem;
  line-height: 1;
}

.val-links {
  color: #fff;
  text-decoration: none;
}

.val-links:visited {
  color: #fff;
  text-decoration: none;
}

@media (min-width: 650px) and (max-width: 1300px) {
  .val-container {
    width: auto;
  }

  .vlogo {
    margin-right: 10px;
  }

  .vnames {
    display: none;
  }
  .searchbar input {
    display: none;
  }
  .searchicon .searchglass {
    display: none;
  }
}

@media (max-width: 1099px) {
  .party-logo {
    display: none;
  }
  .desc-box {
    display: none;
  }
  .desc-desc {
    width: 100%;
  }
}

@media (min-width: 650px) and (max-width: 950px) {
  .results {
    height: fit-content;
  }

  .results-left {
    width: 45%;
  }

  .tallies {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
  }

  .tallyjawn {
    width: 90%;
    margin-bottom: 0;
  }
}

@media (max-width: 799px) {
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: small;
  }

  .no-with {
    display: none;
  }
}

@media (max-width: 649px) {
  .list-house {
    height: 320px;
    margin-top: 50px;
    padding: 15px;
    background-color: #28274f;
    border-radius: 15px;
  }

  .logomain {
    display: none;
  }
  .footer-bigtxt {
    display: none;
  }

  .results {
    height: auto;
  }

  .results-main {
    flex-direction: column;
  }

  .results-left,
  .results-right {
    width: 100%;
  }

  .dough {
    margin-bottom: 20px;
  }

  .results-right {
    margin-left: 0;
  }

  .tallies {
    flex-wrap: wrap;
    justify-content: center;
  }

  .tallyjawn {
    width: 48%;
    margin-bottom: 20px;
  }

  .turnout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .turnout div {
    display: flex;
    justify-content: center;
  }

  .pname {
    font-size: small;
  }

  .val-container {
    width: auto;
  }

  .vlogo {
    margin-right: 0px;
  }

  .vnames {
    display: none;
  }
  .vinfo {
    display: none;
  }
  .searchbar input {
    display: none;
  }
  .searchicon .searchglass {
    display: none;
  }
}

@media (max-width: 500px) {
  .val-container2 {
    display: flex;
    justify-content: space-between;
    width: 300px;
    max-width: 300px;
    height: 70px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    margin-right: 0px;
    background-color: #140f34;
    border-radius: 10px;
  }
}

.skele {
  margin-top: 17px;
}

.val-search {
  display: flex;
  justify-content: space-between;
}

.searchbar {
  display: flex;
}

.searchbar input {
  background-color: #fff;
  border: 0;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 16px;
  padding: 10px;
  height: 12px;
  width: 150px;
}

input:focus {
  outline: none;
}

.searchicon {
  background-color: #fff;
  display: grid;
  place-items: center;
  cursor: pointer;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-right: 10px;
}
